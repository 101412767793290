<template>
  <div data-cy="deductions">
    <v-row class="mt-1 ml-2 mb-5">
      <h2 class="primary--text">Deductions</h2>
    </v-row>
    <v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center v-model="snackBar">
      {{ snackBarText }}
    </v-snackbar>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <!-- search -->
    <v-row data-cy="search-deduction">
      <v-col cols="12" sm="3">
        <v-text-field data-cy="search-deduction-input" solo prepend-inner-icon="mdi-magnify" label="Search by Name"
          dense clearable flat color="primary" background-color="white lighten-4" @click:clear="resetData"
          @keyup.enter="searchDeduction" v-model="query"></v-text-field>
      </v-col>
      <v-col class="mt-n3 ml-n3">
        <v-btn data-cy="deduction-search-btn" color="primary" @click.prevent="searchDeduction">Search</v-btn></v-col>
    </v-row>
    <div class="text-right">
      <v-btn data-cy="add-deduction-btn" color="primary" dark class="mb-5 text-right" @click="addNewDeduction">
        ADD Deduction
      </v-btn>
    </div>
    <v-data-table data-cy="deduction-table" :headers="headers" hide-default-footer :items="deductions"
      class="elevation-1" :page.sync="page" @page-count="pageCount = $event" :items-per-page="itemsPerPage">
      <template v-slot:top>
        <v-dialog data-cy="deduction-dialog" v-model="dialog" max-width="500px">
          <v-form data-cy="deduction-form" lazy-validation v-model="valid" @submit.prevent="submitDeduction"
            ref="deductionForm">
            <v-card data-cy="deduction-card">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete data-cy="deduction-select" :items="tags" item-value="tag" item-text="tag" outlined
                        dense v-model="deductionForm.description" label="Select Deduction"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field data-cy="deduction-total" outlined dense prefix="GH₵"
                        v-model="deductionForm.totalDeduction" label="Total Deduction"></v-text-field>
                    </v-col>

                    <!-- start date -->
                    <v-col cols="12" md="6">
                      <v-menu data-cy="deduction-start-date-menu" ref="startMenu" v-model="startMenu"
                        :close-on-content-click="false" :return-value.sync="deductionForm.startDate"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field data-cy="deduction-start-date" outlined dense v-model="deductionForm.startDate"
                            :rules="[required('Start Month')]" label="Start Month" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker data-cy="deduction-start-date-picker" v-model="deductionForm.startDate"
                          type="month" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn data-cy="cancelBtn" text color="primary" @click="startMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn data-cy="okBtn" text color="primary" @click="
      $refs.startMenu.save(deductionForm.startDate)
      ">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- end date -->
                    <v-col cols="12" md="6">
                      <v-menu data-cy="deduction-end-date-menu" ref="endMenu" v-model="endMenu"
                        :close-on-content-click="false" :return-value.sync="deductionForm.endDate"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field data-cy="deduction-end-date" outlined dense v-model="deductionForm.endDate"
                            :rules="[required('End Month')]" label="End Month" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker data-cy="deduction-end-date-picker" v-model="deductionForm.endDate" type="month"
                          no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn data-cy="cancelBtn" text color="primary" @click="endMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn data-cy="okBtn" text color="primary"
                            @click="$refs.endMenu.save(deductionForm.endDate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete data-cy="deduction-employee" :items="employeeIdsNames" item-text="displayName"
                        item-value="employeeId" multiple outlined :rules="[required('At Least One Employee')]"
                        :readonly="editMode" dense v-model="deductionForm.employeeIds"
                        label="Employee Name"></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="cancelBtn" color="secondary darken-1" @click="dialog = false">
                  Cancel
                </v-btn>

                <v-btn data-cy="deduction-submit" color="primary darken-1" type="submit">
                  {{ btnTitle }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog data-cy="deduction-delete-dialog" v-model="dialogDelete" max-width="500px">
          <v-card data-cy="deduction-delete-card">
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions data-cy="deduction-delete-actions">
              <v-spacer></v-spacer>
              <v-btn data-cy="cancelBtn" color="secondary darken-1" @click="closeDelete">Cancel</v-btn>
              <v-btn data-cy="okBtn" color="primary darken-1" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn data-cy="deduction-status" small :color="setButtonColor(item?.startDate, item?.endDate)"
          @click="progress(item)">
          {{ setProgress(item?.startDate, item?.endDate) }}
        </v-btn>
      </template>
      <!-- Don't remove this yet -->
      <template v-slot:[`item.displayName`]="{ item }">
        {{ item.displayName }}
      </template>

      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employeeId }}
      </template>

      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 + pageNumbering }}
      </template>

      <template v-slot:[`item.amountDue`]="{ item }">{{ formatFigure(item.amountDue) }}
      </template>

      <template v-slot:[`item.monthlyDeduction`]="{ item }">{{ formatFigure(item.monthlyDeduction) }}
      </template>

      <template v-slot:[`item.startDate`]="{ item }">{{
      moment(item.startDate)
    }}</template>

      <template v-slot:[`item.endDate`]="{ item }">{{
      moment(item.endDate)
    }}</template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="deduction-edit" color="primary" dense class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon data-cy="deleteBtn" color="primary" dense @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination data-cy="deduction-pagination" v-model="page" :length="totalPages" @input="paginatePage"
        circle></v-pagination>
    </div>
  </div>
</template>

<script>
import validation from "../services/validation";
import helpers from "../services/helpers";
import pageMixin from "../services/mixins/pageMixin";
import methMixin from "../services/mixins/methMixin";
import employeeMixin from "../services/mixins/employeeMixin";
import methodMixins from "../services/mixins/methodMixins";
import { createHeaders } from "../services/functions";
import moment from "moment";
export default {
  mixins: [helpers, pageMixin, methodMixins, methMixin, employeeMixin],
  data: () => ({
    ...validation,
    valid: false,
    startMenu: false,
    endMenu: false,
    editMode: false,
    dialog: false,
    dialogDelete: false,
    isloading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    query: "",
    itemsPerPage: 20,
    headers: createHeaders("deduction"),
    storePath: "deductions",
    tagType: "Deduction",
    sourcePath: "deductions",
    actionType: "fetchDeduction",
    deductionForm: new Form({
      id: null,
      startDate: "",
      endDate: "",
      monthlyDeduction: null,
      totalDeduction: null,
      description: null,
      employeeIds: [],
    }),
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItem: {},
  }),
  computed: {
    formTitle() {
      return this.editMode ? "Edit Deductions" : "New Deductions";
    },
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * 20 - 20;
      }
    },
    btnTitle() {
      return this.editMode ? "Update" : "Add";
    },
    deductions() {
      return this.$store.getters["deductions/allDeductions"];
    },
  },
  methods: {
    getEmployeeName(id) {
      return (
        this.employeeIdsNames.find((user) => {
          return user.employeeId === id;
        })?.displayName ?? null
      );
    },
    formatFigure(item) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    },
    getEmployeeNumber(id) {
      let employee = this.employeeIdsNames.find((user) => {
        return user.employeeId === id;
      });
      return employee ? employee.employeeNumber : "";
    },
    moment(date) {
      return moment(date).format("MMM YYYY");
    },
    fetchEmployeesIdsNames() {
      this.$store.dispatch("employees/fetchEmployeesIdsNames");
    },
    getAllTags() {
      this.$store.dispatch("additions/fetchAllTags");
    },
    async initialize() {
      this.isloading = true;
      await this.$store.dispatch("deductions/fetchDeduction", 1);
      this.isloading = false;
    },
    editItem(item) {
      let data = {
        id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        monthlyDeduction: item.monthlyDeduction,
        totalDeduction: item.amountDue,
        description: item.description,
        employeeIds: [],
      };
      data.employeeIds.push(item.employeeId);
      this.dialog = true;
      this.deductionForm.fill(data);
      this.editMode = true;
    },

    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store
        .dispatch("deductions/deleteDeduction", [{ id: this.selectedItem.id }])
        .then(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = false;
          this.snackBarText = " Deduction Deleted Successfully";
          this.snackBar = true;
          this.initialize();
        })
        .catch(() => {
          this.selectedItem = {};
          this.closeDelete();
          this.isError = true;
          this.snackBarText = "Error Occurred While Deleting The Item";
          this.snackBar = true;
          this.initialize();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        this.editedItem = {
          ...this.deductions[this.editedIndex],
          ...this.editedItem,
        };
        await this.$store.dispatch("deductions/editDeduction", this.editedItem);
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = "Deduction Edited Successfully";
      } else {
        this.deductions.push(this.editedItem);
        await this.$store.dispatch(
          "deductions/createDeduction",
          this.editedItem
        );
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = " Deduction Created Successfully";
      }
      this.close();
      await this.initialize();
    },

    submitDeduction() {
      // form validation
      if (!this.$refs.deductionForm.validate()) return;

      //amount validation against monthly and total value
      //get months difference
      let monthDiff = this.getMonthsDifference(
        this.deductionForm.startDate,
        this.deductionForm.endDate
      );

      this.deductionForm.monthlyDeduction =
        this.deductionForm.totalDeduction / monthDiff;

      this.isloading = true;
      let data = [];
      this.deductionForm.employeeIds.map((item) => {
        const displayName = this.getEmployeeName(item);
        if (this.editMode) {
          data.push({
            id: this.deductionForm.id,
            startDate: this.deductionForm.startDate,
            endDate: this.deductionForm.endDate,
            monthlyDeduction: parseFloat(this.deductionForm.monthlyDeduction),
            amountDue: parseFloat(this.deductionForm.totalDeduction),
            description: this.deductionForm.description,
            employeeId: item,
            displayName,
          });
        } else {
          data.push({
            // id: this.deductionForm.id,
            startDate: this.deductionForm.startDate,
            endDate: this.deductionForm.endDate,
            monthlyDeduction: parseFloat(this.deductionForm.monthlyDeduction),
            totalDeduction: parseFloat(this.deductionForm.totalDeduction),
            description: this.deductionForm.description,
            employeeId: item,
          });
        }
      });
      this.dialog = false;
      if (this.editMode) {
        this.$store
          .dispatch("deductions/editDeduction", data)
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Deduction Updated Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
            this.initialize();
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Updating Deduction";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      } else {
        this.$store
          .dispatch("deductions/createDeduction", data)
          .then(() => {
            this.isloading = false;
            this.snackBarText = "Deduction Created Successfully";
            this.dialog = false;
            this.isError = false;
            this.snackBar = true;
            this.initialize();
          })
          .catch(() => {
            this.isloading = false;
            this.snackBarText = "Error Occurred While Creating Deduction";
            this.dialog = false;
            this.isError = true;
            this.snackBar = true;
          });
      }
    },

    addNewDeduction() {
      this.editMode = false;
      this.dialog = true;
      this.deductionForm.reset();
    },

    async searchDeduction() {
      if (this.query) {
        this.isloading = true;
        await this.$store.dispatch("deductions/searchDeductions", this.query);
        this.isloading = false;
      } else {
        this.snackBar = true;
        this.snackBarText = " Search input cannot be empty";
      }
    },
  },
};
</script>
<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
  padding: 0 10px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
  font-size: 0.875rem;
  height: 64px;
}
</style>
